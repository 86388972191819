import {
  toReactive,
  useDocumentVisibility,
  useIdle,
  useLocalStorage,
  useMagicKeys,
  useMousePressed,
  useWindowFocus,
  whenever,
} from '@vueuse/core'
import { defineNuxtPlugin } from '#app'
import { computed, ref } from '#imports'

export default defineNuxtPlugin(() => {
  const { idle } = useIdle(300000) // 5 mins timeout

  const { meta, shift } = useMagicKeys()
  const { pressed } = useMousePressed()

  const isTakingScreenshot = ref(false)

  whenever(
    () => meta.value && shift.value,
    () => {
      isTakingScreenshot.value = true
    },
  )

  whenever(
    () => isTakingScreenshot.value && pressed.value,
    () => {
      isTakingScreenshot.value = false
    },
  )

  const appVisible = useDocumentVisibility()
  const appHasFocus = useWindowFocus()
  const protectedActive = useLocalStorage('@railtic/protected', false)

  const allClear = computed(() => {
    if (!protectedActive.value) {
      return true
    }
    return (
      appVisible.value &&
      appHasFocus.value &&
      !idle.value &&
      !isTakingScreenshot.value
    )
  })

  const state = computed(() => ({
    protectedActive: protectedActive.value,
    appVisible: useDocumentVisibility(),
    appHasFocus: useWindowFocus(),
    idle: idle.value,
    isTakingScreenshot: isTakingScreenshot.value,
    allClear: allClear.value,
  }))

  return {
    provide: {
      protected: toReactive(state),
    },
  }
})
