import { defu } from 'defu'
import type { FetchOptions } from 'ofetch'
import { useWorkspace } from '~/stores/workspace'
import {
  makeTracingHeaders,
  useAppConfig,
  useFetch,
  useNuxtApp,
} from '#imports'

const createDefaultOptions = (
  opts: FetchOptions & { [key: string]: string } = {},
) =>
  defu(opts, {
    async onRequest({ options }) {
      const appConfig = useAppConfig()
      const { $auth0, $auth } = useNuxtApp()
      const { getAccessTokenSilently, isAuthenticated } = $auth0()
      const workspace = useWorkspace()

      let token = ''

      if (isAuthenticated.value) {
        token = await getAccessTokenSilently()
      } else if (appConfig.tenant.supportCognitoAuth) {
        token = $auth.accessToken as string
      }

      const headers = {
        Authorization: `Bearer ${token}`,
        'auth-provider': isAuthenticated.value ? 'auth0' : 'cognito',
        'x-rialtic-workspace-id': workspace.workspaceId,
        workspace: workspace.workspaceId,
        ...makeTracingHeaders(),
      }

      options.headers = defu(headers, options.headers)
    },
  })

export const $apiFetcher = () => $fetch.create(createDefaultOptions())

type UseFetch = typeof useFetch

export const useApiFetch: UseFetch = (url, options = {}) =>
  useFetch(url, defu(options, createDefaultOptions()))
