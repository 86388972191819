import { default as _91claimId_93Kl2KNKZupTMeta } from "/builds/rialtic/qa/argocd-releases/console-ui/apps/claim-lab/pages/[connectorId]/claim-lab/[claimId].vue?macro=true";
import { default as indexo0yzZslVtaMeta } from "/builds/rialtic/qa/argocd-releases/console-ui/apps/console/pages/[connectorId]/claim-lab/index.vue?macro=true";
import { default as _91connectorId_93i1YP7rvS1fMeta } from "/builds/rialtic/qa/argocd-releases/console-ui/apps/claim-lab/pages/[connectorId].vue?macro=true";
import { default as api_45clientsVFUr8zL9U6Meta } from "/builds/rialtic/qa/argocd-releases/console-ui/apps/console/pages/account/api-clients.vue?macro=true";
import { default as index0LAIYQtICYMeta } from "/builds/rialtic/qa/argocd-releases/console-ui/apps/console/pages/account/index.vue?macro=true";
import { default as usersAGi3jjcc8pMeta } from "/builds/rialtic/qa/argocd-releases/console-ui/apps/console/pages/account/users.vue?macro=true";
import { default as api_45adminqcHVvE34epMeta } from "/builds/rialtic/qa/argocd-releases/console-ui/apps/console/pages/admin/api-admin.vue?macro=true";
import { default as app_45configROonmlp19XMeta } from "/builds/rialtic/qa/argocd-releases/console-ui/apps/console/pages/admin/app-config.vue?macro=true";
import { default as create_45workspace1sW3IMP6WfMeta } from "/builds/rialtic/qa/argocd-releases/console-ui/apps/console/pages/admin/index/create-workspace.vue?macro=true";
import { default as indexuZ66wdapSfMeta } from "/builds/rialtic/qa/argocd-releases/console-ui/apps/console/pages/admin/index.vue?macro=true";
import { default as _91id_93ttEV57Jo3AMeta } from "/builds/rialtic/qa/argocd-releases/console-ui/apps/console/pages/admin/users/[id].vue?macro=true";
import { default as createQrJrdcYHNeMeta } from "/builds/rialtic/qa/argocd-releases/console-ui/apps/console/pages/admin/users/create.vue?macro=true";
import { default as indexLN786kUmmtMeta } from "/builds/rialtic/qa/argocd-releases/console-ui/apps/console/pages/admin/users/index.vue?macro=true";
import { default as usersxuGJjs4nGzMeta } from "/builds/rialtic/qa/argocd-releases/console-ui/apps/console/pages/admin/users.vue?macro=true";
import { default as _91connId_93F8QQqhcDJrMeta } from "/builds/rialtic/qa/argocd-releases/console-ui/apps/console/pages/admin/workspace/[id]/index/connector/[connId].vue?macro=true";
import { default as indexf6slBgNFMCMeta } from "/builds/rialtic/qa/argocd-releases/console-ui/apps/console/pages/admin/workspace/[id]/index/connector/index.vue?macro=true";
import { default as editOepfgOIEtdMeta } from "/builds/rialtic/qa/argocd-releases/console-ui/apps/console/pages/admin/workspace/[id]/index/edit.vue?macro=true";
import { default as indexxHxerlsflvMeta } from "/builds/rialtic/qa/argocd-releases/console-ui/apps/console/pages/admin/workspace/[id]/index.vue?macro=true";
import { default as _91id_93v1469Za2x5Meta } from "/builds/rialtic/qa/argocd-releases/console-ui/apps/console/pages/admin/workspace/[id].vue?macro=true";
import { default as indexbpOO2lCOzaMeta } from "/builds/rialtic/qa/argocd-releases/console-ui/apps/console/pages/admin/workspace/index.vue?macro=true";
import { default as app_45statusZEnAU896xcMeta } from "/builds/rialtic/qa/argocd-releases/console-ui/apps/console/pages/app-status.vue?macro=true";
import { default as changelogYGFNpog9WhMeta } from "/builds/rialtic/qa/argocd-releases/console-ui/apps/console/pages/changelog.vue?macro=true";
import { default as influencing_45claims5V4wM3daSqMeta } from "/builds/rialtic/qa/argocd-releases/console-ui/apps/console/pages/claims/[id]/[insightId]/influencing-claims.vue?macro=true";
import { default as _91id_939XucZPcfQKMeta } from "/builds/rialtic/qa/argocd-releases/console-ui/apps/console/pages/claims/[id].vue?macro=true";
import { default as claimsOysYGSNpoIMeta } from "/builds/rialtic/qa/argocd-releases/console-ui/apps/console/pages/claims.vue?macro=true";
import { default as dashboard7aDEZpjjxgMeta } from "/builds/rialtic/qa/argocd-releases/console-ui/apps/console/pages/dashboard.vue?macro=true";
import { default as edithE4xXytR0nMeta } from "/builds/rialtic/qa/argocd-releases/console-ui/apps/console/pages/exclusions/[id]/edit.vue?macro=true";
import { default as indexPBwcwJSQ6OMeta } from "/builds/rialtic/qa/argocd-releases/console-ui/apps/console/pages/exclusions/[id]/index.vue?macro=true";
import { default as createRyEyPG0cYZMeta } from "/builds/rialtic/qa/argocd-releases/console-ui/apps/console/pages/exclusions/create.vue?macro=true";
import { default as indexsgjVnnDfxKMeta } from "/builds/rialtic/qa/argocd-releases/console-ui/apps/console/pages/exclusions/index.vue?macro=true";
import { default as exclusions16cBmGOhmaMeta } from "/builds/rialtic/qa/argocd-releases/console-ui/apps/console/pages/exclusions.vue?macro=true";
import { default as indexbW6u8wYtA5Meta } from "/builds/rialtic/qa/argocd-releases/console-ui/apps/console/pages/index.vue?macro=true";
import { default as maintenance1sgkSZCJjXMeta } from "/builds/rialtic/qa/argocd-releases/console-ui/apps/console/pages/maintenance.vue?macro=true";
import { default as providers_45impacted38lZbCxVGbMeta } from "/builds/rialtic/qa/argocd-releases/console-ui/apps/console/pages/policies/[id]/providers-impacted.vue?macro=true";
import { default as _91id_93an0fGmoNVLMeta } from "/builds/rialtic/qa/argocd-releases/console-ui/apps/console/pages/policies/[id].vue?macro=true";
import { default as indexwvymHmXbtUMeta } from "/builds/rialtic/qa/argocd-releases/console-ui/apps/console/pages/policies/index.vue?macro=true";
import { default as policies9OzHSVcefEMeta } from "/builds/rialtic/qa/argocd-releases/console-ui/apps/console/pages/policies.vue?macro=true";
import { default as reset_45password_45confirmDVYBdp7NwMMeta } from "/builds/rialtic/qa/argocd-releases/console-ui/apps/console/pages/reset-password-confirm.vue?macro=true";
import { default as reset_45passwordUYH1NBMxqeMeta } from "/builds/rialtic/qa/argocd-releases/console-ui/apps/console/pages/reset-password.vue?macro=true";
import { default as signinuAfwLtGmyOMeta } from "/builds/rialtic/qa/argocd-releases/console-ui/apps/console/pages/signin.vue?macro=true";
import { default as confirmZzjSyJIsY1Meta } from "/builds/rialtic/qa/argocd-releases/console-ui/apps/console/pages/signup/confirm.vue?macro=true";
import { default as indexaIQvO1UP6SMeta } from "/builds/rialtic/qa/argocd-releases/console-ui/apps/console/pages/signup/index.vue?macro=true";
import { default as signupc5AhWqp4MDMeta } from "/builds/rialtic/qa/argocd-releases/console-ui/apps/console/pages/signup.vue?macro=true";
import { default as welcomeH8NtQEcgulMeta } from "/builds/rialtic/qa/argocd-releases/console-ui/apps/console/pages/welcome.vue?macro=true";
import { default as auth_45callback852wOlvwczMeta } from "/builds/rialtic/qa/argocd-releases/console-ui/packages/nuxt-auth0/src/runtime/pages/auth-callback.vue?macro=true";
import { default as auth_45loginj4HtcCjkEiMeta } from "/builds/rialtic/qa/argocd-releases/console-ui/packages/nuxt-auth0/src/runtime/pages/auth-login.vue?macro=true";
import { default as auth_45statusPp8tL2HSytMeta } from "/builds/rialtic/qa/argocd-releases/console-ui/packages/nuxt-auth0/src/runtime/pages/auth-status.vue?macro=true";
import { default as samlgsDNFHMzJUMeta } from "/builds/rialtic/qa/argocd-releases/console-ui/packages/nuxt-auth/src/pages/saml.vue?macro=true";
export default [
  {
    name: "connectorId",
    path: "/:connectorId()",
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/claim-lab/pages/[connectorId].vue"),
    children: [
  {
    name: "connectorId-claim-lab-claimId",
    path: "claim-lab/:claimId()",
    meta: _91claimId_93Kl2KNKZupTMeta || {},
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/claim-lab/pages/[connectorId]/claim-lab/[claimId].vue")
  },
  {
    name: "connectorId-claim-lab",
    path: "claim-lab",
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/console/pages/[connectorId]/claim-lab/index.vue")
  }
]
  },
  {
    name: "account-api-clients",
    path: "/account/api-clients",
    meta: api_45clientsVFUr8zL9U6Meta || {},
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/console/pages/account/api-clients.vue")
  },
  {
    name: "account",
    path: "/account",
    meta: index0LAIYQtICYMeta || {},
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/console/pages/account/index.vue")
  },
  {
    name: "account-users",
    path: "/account/users",
    meta: usersAGi3jjcc8pMeta || {},
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/console/pages/account/users.vue")
  },
  {
    name: "admin-api-admin",
    path: "/admin/api-admin",
    meta: api_45adminqcHVvE34epMeta || {},
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/console/pages/admin/api-admin.vue")
  },
  {
    name: "admin-app-config",
    path: "/admin/app-config",
    meta: app_45configROonmlp19XMeta || {},
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/console/pages/admin/app-config.vue")
  },
  {
    name: "admin",
    path: "/admin",
    meta: indexuZ66wdapSfMeta || {},
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/console/pages/admin/index.vue"),
    children: [
  {
    name: "admin-index-create-workspace",
    path: "create-workspace",
    meta: create_45workspace1sW3IMP6WfMeta || {},
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/console/pages/admin/index/create-workspace.vue")
  }
]
  },
  {
    name: usersxuGJjs4nGzMeta?.name,
    path: "/admin/users",
    meta: usersxuGJjs4nGzMeta || {},
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/console/pages/admin/users.vue"),
    children: [
  {
    name: "admin-users-id",
    path: ":id()",
    meta: _91id_93ttEV57Jo3AMeta || {},
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/console/pages/admin/users/[id].vue")
  },
  {
    name: "admin-users-create",
    path: "create",
    meta: createQrJrdcYHNeMeta || {},
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/console/pages/admin/users/create.vue")
  },
  {
    name: "admin-users",
    path: "",
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/console/pages/admin/users/index.vue")
  }
]
  },
  {
    name: _91id_93v1469Za2x5Meta?.name,
    path: "/admin/workspace/:id()",
    meta: _91id_93v1469Za2x5Meta || {},
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/console/pages/admin/workspace/[id].vue"),
    children: [
  {
    name: "admin-workspace-id",
    path: "",
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/console/pages/admin/workspace/[id]/index.vue"),
    children: [
  {
    name: "admin-workspace-id-index-connector-connId",
    path: "connector/:connId()",
    meta: _91connId_93F8QQqhcDJrMeta || {},
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/console/pages/admin/workspace/[id]/index/connector/[connId].vue")
  },
  {
    name: "admin-workspace-id-index-connector",
    path: "connector",
    meta: indexf6slBgNFMCMeta || {},
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/console/pages/admin/workspace/[id]/index/connector/index.vue")
  },
  {
    name: "admin-workspace-id-index-edit",
    path: "edit",
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/console/pages/admin/workspace/[id]/index/edit.vue")
  }
]
  }
]
  },
  {
    name: "admin-workspace",
    path: "/admin/workspace",
    meta: indexbpOO2lCOzaMeta || {},
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/console/pages/admin/workspace/index.vue")
  },
  {
    name: "app-status",
    path: "/app-status",
    meta: app_45statusZEnAU896xcMeta || {},
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/console/pages/app-status.vue")
  },
  {
    name: "changelog",
    path: "/changelog",
    meta: changelogYGFNpog9WhMeta || {},
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/console/pages/changelog.vue")
  },
  {
    name: "claims",
    path: "/claims",
    meta: claimsOysYGSNpoIMeta || {},
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/console/pages/claims.vue"),
    children: [
  {
    name: "claims-id",
    path: ":id()",
    meta: _91id_939XucZPcfQKMeta || {},
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/console/pages/claims/[id].vue"),
    children: [
  {
    name: "claims-id-insightId-influencing-claims",
    path: ":insightId()/influencing-claims",
    meta: influencing_45claims5V4wM3daSqMeta || {},
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/console/pages/claims/[id]/[insightId]/influencing-claims.vue")
  }
]
  }
]
  },
  {
    name: "dashboard",
    path: "/dashboard",
    meta: dashboard7aDEZpjjxgMeta || {},
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/console/pages/dashboard.vue")
  },
  {
    name: exclusions16cBmGOhmaMeta?.name,
    path: "/exclusions",
    meta: exclusions16cBmGOhmaMeta || {},
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/console/pages/exclusions.vue"),
    children: [
  {
    name: "exclusions-id-edit",
    path: ":id()/edit",
    meta: edithE4xXytR0nMeta || {},
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/console/pages/exclusions/[id]/edit.vue")
  },
  {
    name: "exclusions-id",
    path: ":id()",
    meta: indexPBwcwJSQ6OMeta || {},
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/console/pages/exclusions/[id]/index.vue")
  },
  {
    name: "exclusions-create",
    path: "create",
    meta: createRyEyPG0cYZMeta || {},
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/console/pages/exclusions/create.vue")
  },
  {
    name: "exclusions",
    path: "",
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/console/pages/exclusions/index.vue")
  }
]
  },
  {
    name: "index",
    path: "/",
    meta: indexbW6u8wYtA5Meta || {},
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/console/pages/index.vue")
  },
  {
    name: "maintenance",
    path: "/maintenance",
    meta: maintenance1sgkSZCJjXMeta || {},
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/console/pages/maintenance.vue")
  },
  {
    name: policies9OzHSVcefEMeta?.name,
    path: "/policies",
    meta: policies9OzHSVcefEMeta || {},
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/console/pages/policies.vue"),
    children: [
  {
    name: "policies-id",
    path: ":id()",
    meta: _91id_93an0fGmoNVLMeta || {},
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/console/pages/policies/[id].vue"),
    children: [
  {
    name: "policies-id-providers-impacted",
    path: "providers-impacted",
    meta: providers_45impacted38lZbCxVGbMeta || {},
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/console/pages/policies/[id]/providers-impacted.vue")
  }
]
  },
  {
    name: "policies",
    path: "",
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/console/pages/policies/index.vue")
  }
]
  },
  {
    name: "reset-password-confirm",
    path: "/reset-password-confirm",
    meta: reset_45password_45confirmDVYBdp7NwMMeta || {},
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/console/pages/reset-password-confirm.vue")
  },
  {
    name: "reset-password",
    path: "/reset-password",
    meta: reset_45passwordUYH1NBMxqeMeta || {},
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/console/pages/reset-password.vue")
  },
  {
    name: "signin",
    path: "/signin",
    meta: signinuAfwLtGmyOMeta || {},
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/console/pages/signin.vue")
  },
  {
    name: signupc5AhWqp4MDMeta?.name,
    path: "/signup",
    meta: signupc5AhWqp4MDMeta || {},
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/console/pages/signup.vue"),
    children: [
  {
    name: "signup-confirm",
    path: "confirm",
    meta: confirmZzjSyJIsY1Meta || {},
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/console/pages/signup/confirm.vue")
  },
  {
    name: "signup",
    path: "",
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/console/pages/signup/index.vue")
  }
]
  },
  {
    name: "welcome",
    path: "/welcome",
    meta: welcomeH8NtQEcgulMeta || {},
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/console/pages/welcome.vue")
  },
  {
    name: "auth-callback",
    path: "/auth/callback",
    meta: auth_45callback852wOlvwczMeta || {},
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/packages/nuxt-auth0/src/runtime/pages/auth-callback.vue")
  },
  {
    name: "auth-login",
    path: "/auth/login",
    meta: auth_45loginj4HtcCjkEiMeta || {},
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/packages/nuxt-auth0/src/runtime/pages/auth-login.vue")
  },
  {
    name: "auth-status",
    path: "/auth/status",
    meta: auth_45statusPp8tL2HSytMeta || {},
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/packages/nuxt-auth0/src/runtime/pages/auth-status.vue")
  },
  {
    name: "AuthSaml",
    path: "/saml",
    meta: samlgsDNFHMzJUMeta || {},
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/packages/nuxt-auth/src/pages/saml.vue")
  }
]