import routerOptions0 from "/builds/rialtic/qa/argocd-releases/console-ui/node_modules/.pnpm/nuxt@3.13.2_@biomejs+biome@1.8.3_@types+node@20.16.11_stylelint@15.11.0_typescript@5.6.3_vite@5.4.10_vue-tsc@1.8.27/node_modules/nuxt/dist/pages/runtime/router.options";
import routerOptions1 from "/builds/rialtic/qa/argocd-releases/console-ui/apps/console/app/router.options.ts";
const configRouterOptions = {
  hashMode: false,
  scrollBehaviorType: "auto"
}
export default {
...configRouterOptions,
...routerOptions0,
...routerOptions1,
}