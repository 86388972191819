import { datadogRum } from '@datadog/browser-rum'
import { defineNuxtPlugin, useRuntimeConfig } from '#app'

export default defineNuxtPlugin({
  name: 'nuxt-datadog-rum',
  async setup() {
    const options = useRuntimeConfig().public.datadogRum

    datadogRum.init({
      ...options.config,
      allowedTracingUrls: [/https:\/\/.*\.rialtic\.app/],
    })

    datadogRum.startSessionReplayRecording()

    return {
      provide: {
        datadog: datadogRum,
      },
    }
  },
})
