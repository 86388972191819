import { defineNuxtPlugin, useRuntimeConfig } from '#imports'
import { stubbedPendo } from './helpers'
import { initPendo } from './pendo-snippet'

export default defineNuxtPlugin(() => {
  const key = useRuntimeConfig().public.pendoKey as string | undefined

  if (!key) {
    return {
      provide: {
        pendo: stubbedPendo,
      },
    }
  }

  initPendo(key)

  pendo.initialize({
    visitor: {
      id: 'VISITOR-UNIQUE-ID',
    },
  })

  return {
    provide: {
      pendo: window.pendo,
    },
  }
})
