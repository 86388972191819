<script setup lang="ts">
import { computed } from '#imports'

const props = defineProps<{
  appear?: boolean
  appearActiveClass?: string
  enterActiveClass?: string
  leaveActiveClass?: string
}>()

const classes = computed(() => ({
  appear: !!props.appear,
  ...(props.appear
    ? {
        appearActiveClass: `transition-opacity ${props.appearActiveClass}`,
        appearFromClass: 'opacity-0',
        appearToClass: 'opacity-100',
      }
    : null),
  enterActiveClass: `transition-opacity ${props.enterActiveClass}`,
  enterFromClass: 'opacity-0',
  enterToClass: 'opacity-100',
  leaveActiveClass: `transition-opacity ${props.leaveActiveClass}`,
  leaveFromClass: 'opacity-100',
  leaveToClass: 'opacity-0',
}))
</script>

<template>
  <Transition v-bind="classes" name="fade">
    <slot />
  </Transition>
</template>
