type PendoVisitor = {
  /** Required if user is logged in, default creates anonymous ID */
  id: string

  /** Recommended if using Pendo Feedback, or NPS Email */
  email?: string

  /** Recommended if using Pendo Feedback */
  full_name?: string

  role?: string
}

type PendoAccount = {
  /** Required if using Pendo Feedback, default uses the value 'ACCOUNT-UNIQUE-ID' */
  id: string
  name?: string
  /** Recommended if using Pendo Feedback */
  is_paying?: boolean
  /**  Recommended if using Pendo Feedback */
  monthly_value?: number
  planLevel?: string
  planPrice?: number
  creationDate?: string

  // Rialtic values for segmentation
  /** Rialtic app */
  rialtic_app: string
} & { [key: string]: string | number | boolean | undefined }

interface PendoSetupOptions {
  visitor: PendoVisitor
  account: PendoAccount
  disableGuides?: boolean
  excludeAllText?: boolean
}

export interface Pendo {
  getAccountId: () => string
  getCurrentUrl: () => string
  getVersion: () => string
  getVisitorId: () => string
  identify: (options: PendoSetupOptions) => void
  initialize: (options: PendoSetupOptions) => void
  isReady: () => boolean
  flushNow: () => Promise<void>
  updateOptions: (options: Partial<PendoSetupOptions>) => void
}

export const stubbedPendo: Pendo = {
  getAccountId: () => '',
  getCurrentUrl: () => '',
  getVersion: () => '',
  getVisitorId: () => '',
  identify: () => {},
  initialize: () => {},
  isReady: () => false,
  flushNow: () => Promise.resolve(),
  updateOptions: () => {},
}
