export type PermissionRule = 'ANY' | 'ALL'

export const guard = (
  rule: PermissionRule,
  guardPermissions: string[],
  userPermissions?: string[],
): boolean => {
  if (!guardPermissions?.length) {
    return true
  }
  if (!userPermissions?.length) {
    return false
  }
  if (rule === 'ALL') {
    return guardPermissions.every((p) => userPermissions.includes(p))
  }

  return guardPermissions.some((p) => userPermissions.includes(p))
}

export const PERMISSIONS_KEY = 'permissions'

/** WARNING: Permissions need to be maintained in multiple places
 * Instructions in Notion
 * https://www.notion.so/rialtic/Managing-Workflows-Auth0-Permissions-d424c696d6e946b6aa5e83b0da38259c?pvs=4
 */
export const PERMISSIONS = <const>{
  readConfig: 'read:config',
  writeConfig: 'write:config',
  readSystem: 'read:system',
  writeSystem: 'write:system',
  readTeamContent: 'read:team-content',
  writeTeamContent: 'write:team-content',
  publishPolicies: 'publish:policies',
  readUsersAll: 'read:users-all',
  writeUsersAll: 'write:users-all',
  readWorkspacesAll: 'read:workspaces-all',
  writeWorkspacesAll: 'write:workspaces-all',
}

export type Permission = (typeof PERMISSIONS)[keyof typeof PERMISSIONS]

// Workspace permissions
export const WORKSPACE_PERMISSIONS_KEY = 'workspace_permissions'

/** WARNING: Permissions need to be maintained in multiple places
 * Instructions in Notion
 * https://www.notion.so/rialtic/Managing-Workflows-Auth0-Permissions-d424c696d6e946b6aa5e83b0da38259c?pvs=4
 */

export const WORKSPACE_PERMISSION = <const>{
  readAnalytics: 'read:analytics',
  readPhi: 'read:phi',
  readWorkspace: 'read:workspace',
  writeWorkspace: 'write:workspace',
  readConnectorConfig: 'read:connector-config',
  writeConnectorConfig: 'write:connector-config',
  readConnector: 'read:connector',
  writeConnector: 'write:connector',
  readSystem: 'read:system',
  writeSystem: 'write:system',
}

export type WorkspacePermission =
  (typeof WORKSPACE_PERMISSION)[keyof typeof WORKSPACE_PERMISSION]

export const getWorkspaceIdFromPermission = (
  permission: string,
): string | null => permission.match(/^workspace:(\w+)#(.+)/)?.[1] ?? null

export const getWorkspaceIdsFromPermissions = (userPermissions: string[]) => {
  const workspaceIds: (string | null)[] = userPermissions
    .filter((permission) => permission.startsWith('workspace:'))
    .map(getWorkspaceIdFromPermission)

  return new Set(workspaceIds.filter((id) => id !== null))
}

export const getWorkspacePermission = (
  workspaceId: string,
  permission: WorkspacePermission,
): string => `workspace:${workspaceId}#${permission}`

export const hasAnyWorkspacePermission = (
  userPermissions: string[],
  workspaceId?: string,
): boolean => {
  if (!workspaceId) return false
  return (
    userPermissions.filter((p) => p.startsWith(`workspace:${workspaceId}`))
      .length > 0
  )
}

export const hasWorkspacePermission = (
  userPermissions: string[],
  workspaceId: string | undefined,
  permission: WorkspacePermission | WorkspacePermission[],
): boolean => {
  if (!workspaceId) return false
  if (Array.isArray(permission)) {
    return permission.some((p) =>
      hasWorkspacePermission(userPermissions, workspaceId, p),
    )
  }
  const workspacePermission = getWorkspacePermission(workspaceId, permission)
  return userPermissions.includes(workspacePermission)
}
