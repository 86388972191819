import { createSharedComposable } from '@vueuse/core'
import { defu } from 'defu'
import { computed } from '#imports'
import { useRouteQuery } from './sharedComposables'
import { FilterNames } from '@rialtic/types'

type UrlParamsState = Record<string, string[] | string>

interface UrlParamsStateOptions<T> {
  /**
   * @default true
   */
  removeNullishValues?: boolean
  /**
   * @default false
   */
  removeFalsyValues?: boolean
  /**
   * @default {}
   */
  initialValue?: T
  /**
   * Write back to `window.history` automatically
   *
   * @default true
   */
  write?: boolean
}

const DELIMITER = '|'
const SINGLE_VALUE_PARAMS: string[] = [
  'insight_mode',
  'selected_edit',
  'line_date_of_service_start',
  'line_date_of_service_end',
]

const arrayToPipes = (array: string | string[]) =>
  Array.isArray(array) ? array.join(DELIMITER) : array

const pipesToArray = (pipes: string | string[]) => {
  if (!pipes) return []

  return Array.isArray(pipes) ? pipes : pipes.split(DELIMITER)
}

const paramsToPipes = (params: Record<string, string | string[]>) =>
  Object.entries(params).reduce<Record<string, string>>(
    (state, [key, value]) => {
      state[key] = arrayToPipes(value)
      return state
    },
    {},
  )

/**
 * Processes parameters and structures output data according to business rules
 * @param (Record<string, string>) - URL parameters
 * @returns (Record<string, string[] | string | boolean>) - Processed parameters for state storage
 */
const processedParams = (params: Record<string, string>) =>
  Object.entries(params).reduce<Record<string, string[] | string | boolean>>(
    (state, [key, value]) => {
      const valueToLower = value.toLowerCase()

      if (value && SINGLE_VALUE_PARAMS.includes(key))
        state[key] = valueToLower === 'true' || valueToLower === 'false' ? JSON.parse(valueToLower) : value
      else if (value) state[key] = pipesToArray(value)

      return state
    },
    {},
  )

const DEFAULT_QUERY_PARAMS = ['lob', 'start_date', 'end_date']

export const useUrlParamsState = createSharedComposable(
  (
    mode?: 'hash' | 'hash-params' | 'history',
    options?: UrlParamsStateOptions<UrlParamsState>,
  ) => {
    const opts = defu(
      {
        initialValue: paramsToPipes(options?.initialValue ?? {}),
      },
      options,
    )

    const activeUrlParams = useRouteQuery('url-query-state', opts)
    /**
     * A list of normalized URL parameters
     * @see {@link activeUrlParams}
     * @see {@link processedParams}
     * @returns (Record<string, string[] | string | boolean>) - Normalized list of URL parameters
     */
    const normalizedParams = computed(() => {
      const params = processedParams(activeUrlParams)

      if (Object.hasOwn(params, FilterNames.DOS_RANGE)) {
        const [start, end] = params[FilterNames.DOS_RANGE] as string[]
        params[FilterNames.LINE_DOS_START] = start

        // TODO: implement validation to get around this
        if (end) params[FilterNames.LINE_DOS_END] = end
      }

      return params
    })

    const getPathWithParams = (
      path: string,
      include = DEFAULT_QUERY_PARAMS,
    ) => {
      const pickedParams: Record<string, string> = {}

      for (const key of include) {
        if (!activeUrlParams[key] || activeUrlParams[key] === 'undefined')
          continue
        pickedParams[key] = activeUrlParams[key]
      }

      const searchParams = new URLSearchParams(pickedParams)
      return `${path}?${searchParams.toString()}`
    }

    return {
      activeUrlParams,
      clearUrlParams(keep = DEFAULT_QUERY_PARAMS) {
        for (const key of Object.keys(activeUrlParams)) {
          if (!keep.includes(key)) delete activeUrlParams[key]
        }
      },

      deleteUrlParam(param: string) {
        delete activeUrlParams[param]
      },

      getPathWithParams,

      patchUrlParams(
        updates: Record<string, string | string[]>,
        overwrite = false,
      ) {
        const params: Record<string, string> = {}

        for (const [key, value] of Object.entries(updates)) {
          params[key] = arrayToPipes(value)
        }

        if (overwrite) {
          Object.assign(activeUrlParams, params)
        } else {
          Object.assign(activeUrlParams, { ...activeUrlParams, ...params })
        }
      },

      setUrlParams(param: string, value: string | string[]) {
        activeUrlParams[param] = arrayToPipes(value)
      },

      getUrlParams: (param: string) => pipesToArray(activeUrlParams[param]),

      normalizedParams,
    }
  },
)
