import { useLocalStorage } from '@vueuse/core'
import jwtDecode from 'jwt-decode'
import { acceptHMRUpdate, defineStore } from 'pinia'
import { nextTick, ref, useRuntimeConfig } from '#imports'
import { useAccessToken } from '../composables/fetchWithJwt'

import { tokenManager } from '../utils/tokens'

interface JwtPayload {
  auth_time: number
  client_id: string
  'cognito:groups': string[]
  event_id: string
  exp: number
  iat: number
  iss: string
  jti: string
  origin_jti: string
  scope: string
  sub: string
  token_use: 'access'
  username: string
}
interface TokenResult {
  accessToken: string
  refreshToken: string
  error?: any
}

interface AuthProfile {
  email: string
  firstName: string
  lastName: string
  sub: string
}

interface SamlConfig {
  loginUrl: string
}

const createAuthApi = () => {
  const config = useRuntimeConfig().public

  return $fetch.create({
    baseURL: config.AUTH_API_BASE_URL,
  })
}

export const useAuthorization = defineStore('authorization', {
  state: () => {
    const authEmail = useLocalStorage('@rialtic/authEmail', '')
    const cognitoGroups = ref<string[]>([])
    const profile = ref({} as AuthProfile)
    const userId = ref('')
    const workspaceId = useLocalStorage('@rialtic/workspaceId', '')

    let refreshToken: string | null = null

    try {
      refreshToken = tokenManager.getRefreshToken()
    } catch (_error) {}

    return {
      accessToken: useAccessToken(),
      authEmail,
      cognitoGroups,
      fetchingProfile: false,
      profile,
      refreshToken,
      userId,
      workspaceId,
      tokenExpiresAt: 0,
    }
  },

  getters: {
    hasRole: (state) => (role: string) => state.cognitoGroups.includes(role),

    isAdmin(): boolean {
      return this.hasRole('admin@rialtic.io')
    },
  },

  actions: {
    async apiCheck() {
      const authFetch = createAuthApi()

      return authFetch('/', { method: 'POST', retry: 3 })
    },

    async checkAndRefresh(): Promise<string> {
      const refreshToken = tokenManager.getRefreshToken()
      if (!refreshToken) throw new Error('no_refresh_token')

      if (refreshToken !== this.refreshToken) {
        this.refreshToken = refreshToken
      }

      try {
        return this.decodeJwt()
      } catch (error) {
        if (!(error instanceof Error) || error.message !== 'token_expired')
          throw error

        try {
          const { accessToken } = await this.tokenRefresh(refreshToken)
          return this.decodeJwt(accessToken)
        } catch (ex) {
          throw ex
        }
      }
    },

    decodeJwt(token?: string): string {
      try {
        const tkn = token || tokenManager.getAccessToken()

        if (!tkn) {
          throw new Error('no_token')
        }

        const payload: JwtPayload = jwtDecode(tkn)
        const tokenExpiresAt = (payload.exp || 1) * 1000

        this.$patch({
          accessToken: tkn,
          cognitoGroups: payload?.['cognito:groups']
            ? payload['cognito:groups']
            : [],
          userId: payload?.sub || '',
          tokenExpiresAt,

          ...(tkn !== this.accessToken
            ? {
                accessToken: tkn,
              }
            : null),
        })

        const timeRemaining = tokenExpiresAt - Date.now()
        if (timeRemaining <= 0) {
          throw new Error('token_expired')
        }

        if (timeRemaining < 1000 * 60 * 10 && this.refreshToken) {
          this.tokenRefresh(this.refreshToken)
        }

        if (this.profile?.sub !== payload?.sub && !this.fetchingProfile)
          this.getProfile()

        return tkn
      } catch (error) {
        throw error
      }
    },

    async getProfile(token?: string): Promise<Partial<AuthProfile> | null> {
      this.fetchingProfile = true
      const tkn = token || tokenManager.getAccessToken()
      if (!tkn) {
        this.profile = {}
        return Promise.resolve(null)
      }

      const authFetch = createAuthApi()

      try {
        const profile = await authFetch<AuthProfile>('/profile', {
          credentials: 'include',
          headers: {
            Authorization: `Bearer ${tkn}`,
          },
        })

        this.$patch({
          fetchingProfile: false,
          userId: profile.sub,
        })

        // Not included in patch to ensure watch => $auth.profile works
        this.profile = profile

        return profile
      } catch (error) {
        return Promise.resolve(null)
      }
    },

    getSamlConfig() {
      const authFetch = createAuthApi()

      return authFetch<SamlConfig>('/saml-config')
    },

    async loginWithOauthCode(code: string) {
      const authFetch = createAuthApi()

      tokenManager.clear()
      this.$patch({
        accessToken: null,
        refreshToken: null,
        authEmail: '',
        cognitoGroups: [],
        userId: '',
      })

      const res = await authFetch<TokenResult>('/saml-auth', {
        method: 'POST',
        body: {
          code,
        },
      })

      if (res?.error) {
        throw new Error(res.error?.message || 'auth error')
      }

      const { accessToken, refreshToken } = res

      if (!accessToken) {
        throw new Error('No accessToken found')
      }

      this.sendLog('auth_login', accessToken)

      this.setAccessToken(accessToken)
      this.setRefreshToken(refreshToken)
      await nextTick()
      return true
    },

    async login(username: string, password: string) {
      const authFetch = createAuthApi()

      tokenManager.clear()
      this.$patch({
        accessToken: null,
        refreshToken: null,
        authEmail: '',
        cognitoGroups: [],
        userId: '',
      })
      const res = await authFetch<TokenResult>('/login', {
        credentials: 'include',
        method: 'POST',
        body: {
          username,
          password,
        },
      })

      if (res?.error) {
        throw new Error(res.error?.message || 'auth error')
      }

      const { accessToken, refreshToken } = res

      if (!accessToken) {
        throw new Error('No accessToken found')
      }

      this.sendLog('auth_login', accessToken)

      this.setAccessToken(accessToken)
      this.setRefreshToken(refreshToken)
      await nextTick()
      return true
    },

    async passwordResetCode(email: string) {
      const authFetch = createAuthApi()

      this.authEmail = email

      const res = await authFetch<any>('/forgot-password', {
        credentials: 'include',
        method: 'POST',
        body: {
          username: email,
        },
      })

      if (res?.error) {
        throw new Error(res.error?.message || 'reset password error')
      }

      return res
    },

    async passwordResetConfirm(
      username: string,
      password: string,
      code: string,
    ) {
      const authFetch = createAuthApi()
      const res = await authFetch<any>('/forgot-password/confirm', {
        credentials: 'include',
        method: 'POST',
        body: {
          username,
          password,
          code,
        },
      })

      if (res?.error) {
        throw new Error(res.error?.message || 'reset password error')
      }

      return res
    },

    sendLog(action: string, token?: string) {
      const authFetch = createAuthApi()
      return authFetch<TokenResult>('/auth-log', {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token || tokenManager.getAccessToken()}`,
        },
        body: {
          action,
        },
      })
    },

    setAccessToken(token: string) {
      if (!process.client) return false

      const tokenChanged = this.accessToken !== token

      const accessToken = useAccessToken()
      accessToken.value = token
      tokenManager.setAccessToken(token)

      if (token && tokenChanged) {
        this.accessToken = token
        accessToken.value = token

        this.getProfile(token)
      }
      return true
    },

    setRefreshToken(token: string) {
      tokenManager.setRefreshToken(token)
      this.refreshToken = token
      return true
    },

    signup(body) {
      const authFetch = createAuthApi()

      this.authEmail = body.username

      return authFetch('/signup', {
        method: 'POST',
        credentials: 'include',
        body,
      })
    },

    signupConfirm(body) {
      const authFetch = createAuthApi()

      return authFetch('/signup/confirm', {
        method: 'POST',
        credentials: 'include',
        body,
      })
    },

    async signupResendCode(username: string) {
      const authFetch = createAuthApi()
      const res = await authFetch<any>('/signup/resend-confirm-code', {
        credentials: 'include',
        method: 'POST',
        body: {
          username,
        },
      })

      if (res?.error) {
        throw new Error(res.error?.message || 'reset password error')
      }
    },

    async tokenInvalidate() {
      const refreshToken = tokenManager.getRefreshToken()

      if (tokenManager.getAccessToken()) {
        await this.sendLog('auth_logout')
      }

      tokenManager.clear()
      const accessToken = useAccessToken()
      accessToken.value = ''

      this.$patch({
        accessToken: null,
        profile: {},
        refreshToken: null,
        userId: '',
      })

      const authFetch = createAuthApi()

      if (refreshToken)
        await authFetch('/token/invalidate', {
          credentials: 'include',
          method: 'POST',
          body: {
            refreshToken,
          },
        })

      this.$reset()
    },

    async tokenRefresh(refreshToken: string) {
      const authFetch = createAuthApi()

      try {
        const { accessToken } = await authFetch<any>('/token/refresh', {
          credentials: 'include',
          method: 'POST',
          body: {
            refreshToken,
          },
        })
        this.setAccessToken(accessToken)
        return { accessToken }
      } catch (error) {
        tokenManager.clear()
        throw error
      }
    },

    async tokenVerify() {
      const authFetch = createAuthApi()

      const res = await authFetch<any>('/token/verify', {
        credentials: 'include',
        method: 'POST',
        headers: {
          Authorization: `Bearer ${this.accessToken}`,
        },
      })

      this.cognitoGroups = res?.['cognito:groups'] || []
      this.userId = res?.sub || ''

      if (!this.profile?.email) {
        this.getProfile(this.accessToken)
      }
      return res
    },
  },
})

if (import.meta.hot)
  import.meta.hot.accept(acceptHMRUpdate(useAuthorization, import.meta.hot))
