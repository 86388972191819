export const useClock = () => {
  const MILLISECONDS_PER_MINUTE = 60000
  const clock = createSharedComposable(useTimestamp)
  const timestamp = useThrottle(clock(), 1000)
  const hourOfDay = computed(() => new Date(timestamp.value).getHours())

  const today = computed(() => {
    return normalizeDate(new Date(timestamp.value))
  })

  const todayDate = computed(() => {
    // toISOString returns a UTC date so we need to offset it
    const convertedISO = new Date(
      today.value.getTime() -
        today.value.getTimezoneOffset() * MILLISECONDS_PER_MINUTE,
    ).toISOString()
    return stripTimeFromDate(convertedISO)
  })

  return {
    hourOfDay,
    timestamp,
    today,
    todayDate,
  }
}
