import type { DatePickerRange } from '@rialtic/types'
import { isToday, startOfQuarter, startOfYear, subDays } from 'date-fns'
import {
  DateRangePresetKey,
  datePresets,
  dateRangePattern,
  dateRangePatternAlt,
} from '~/constants'
import { useWorkspace } from '~/stores/workspace'

export type { DatePickerRange }

export type PresetItem = {
  id: Omit<DateRangePresetKey, DateRangePresetKey.Custom>
  label: (typeof datePresets)[DateRangePresetKey]
  start: string
  end: string
}

export type PresetItemCustom = {
  id: DateRangePresetKey.Custom
  label: (typeof datePresets)[DateRangePresetKey.Custom]
  start: undefined
  end: undefined
}

/**
 * Gets Date Range from first of the year to Current Date
 * @param currentDate Provides current date and supports reactivity
 * @returns {DatePickerRange}
 */
export const getYearToDateRange = (
  currentDate = new Date(),
): DatePickerRange => {
  return {
    start: formatDisplayDate(startOfYear(currentDate), dateRangePattern),
    end: formatDisplayDate(currentDate, dateRangePattern),
    preset: datePresets.yearToDate,
  }
}

export function useDatePresets() {
  const workspace = useWorkspace()
  const { today, todayDate } = useClock()

  /**
   * Gets Date Range where Start Date is offset by number of provided days from Current Date
   * @param currentDate Provides current date and supports reactivity
   * @param days Days to offset backwards from Current Date
   * @param preset Name of preset to be returned in new DatePickerRange
   * @returns {DatePickerRange}
   */
  const getDateRangeOffsetByDays = (
    currentDate: Date,
    days: number,
  ): DatePickerRange => {
    return {
      // We need to subtract a day for the current day since we include it in the range
      start: formatDisplayDate(
        subDays(currentDate, days - 1),
        dateRangePattern,
      ),
      end: formatDisplayDate(currentDate, dateRangePattern),
    }
  }

  /**
   * Gets Date Range starting with closest Quarter Date to Current Date
   * @param currentDate Provides current date and supports reactivity
   * @returns {DatePickerRange}
   */
  const getQuarterToDateRange = (currentDate: Date): DatePickerRange => {
    const closestQuarterStartDate = startOfQuarter(currentDate)

    return {
      start: formatDisplayDate(closestQuarterStartDate, dateRangePattern),
      end: formatDisplayDate(currentDate, dateRangePattern),
    }
  }

  const sinceInceptionPreset = computed(() => {
    return {
      id: DateRangePresetKey.SinceInception,
      label: datePresets.sinceInception,
      start: workspace.sinceInceptionDate,
      end: todayDate.value,
    }
  })

  const customPreset: PresetItemCustom = {
    id: DateRangePresetKey.Custom,
    label: datePresets.custom,
    start: undefined,
    end: undefined,
  }

  const presets = computed(() => {
    const current = today.value
    return [
      {
        id: DateRangePresetKey.LastSeven,
        label: datePresets.lastSeven,
        ...getDateRangeOffsetByDays(current, 7),
      } as PresetItem,
      {
        id: DateRangePresetKey.LastThirty,
        label: datePresets.lastThirty,
        ...getDateRangeOffsetByDays(current, 30),
      } as PresetItem,
      {
        id: DateRangePresetKey.LastNinety,
        label: datePresets.lastNinety,
        ...getDateRangeOffsetByDays(current, 90),
      } as PresetItem,
      {
        id: DateRangePresetKey.QuarterToDate,
        label: datePresets.quarterToDate,
        ...getQuarterToDateRange(current),
      } as PresetItem,
      {
        id: DateRangePresetKey.YearToDate,
        label: datePresets.yearToDate,
        ...getYearToDateRange(current),
      } as PresetItem,
      sinceInceptionPreset.value,
      customPreset,
    ]
  })

  const activePreset = computed(() => {
    if (!isToday(localDate(workspace.selectedDateRange.end))) {
      return customPreset
    }

    return (
      presets.value.find(
        (preset) => preset.start === workspace.selectedDateRange.start,
      ) || customPreset
    )
  })

  const getPresetByName = (presetName: string) => {
    return (
      presets.value.find((preset) => preset.label === presetName) ??
      customPreset
    )
  }

  const activePresetWithDate = computed(() => {
    if (workspace.isClaimEval) {
      return (workspace.activeConnector?.batch_label as string) || 'All time'
    }
    const { start, end } = workspace.selectedDateRange
    const f = (date: string) => formatDisplayDate(date, dateRangePatternAlt)
    return `${activePreset.value.label} (${f(start)} - ${f(end)})`
  })

  return {
    activePreset,
    currentPresetDate: today,
    getPresetByName,
    presets,
    activePresetWithDate,
    sinceInceptionPreset,
  }
}
