import { useWorkspace } from '~/stores/workspace'
import { parseJSON } from 'date-fns'

type TimezoneMetadata = (typeof timezoneMetadata)[TimeZoneName] & {
  key: TimeZoneName | string
}

const DEFAULT_TIMEZONE = 'America/New_York'
export const useWorkspaceTimezone = () => {
  const workspace = useWorkspace()

  const timezoneMeta = computed<TimezoneMetadata | null>(() => {
    const timezoneKey = (workspace.activeWorkspace?.timezone ??
      DEFAULT_TIMEZONE) as TimeZoneName
      
    const meta = timezoneMetadata[timezoneKey]

    if (meta) {
      return { ...meta, key: timezoneKey }
    }

    return null
  })

  const timezone = computed(() => {
    return workspace.activeWorkspace?.timezone as TimeZoneName
  })

  const format = (
    date: Date | string,
    formatOptions: Intl.DateTimeFormatOptions,
  ) => {
    const formatter = timezoneFormatter(timezone.value, formatOptions)
    try {
      const d = date instanceof Date ? date : parseJSON(date)
      return formatter.format(d)
    } catch (error) {
      return ''
    }
  }

  const formattedDate = (
    date: MaybeRef<Date | string>,
    formatOptions: Intl.DateTimeFormatOptions,
  ) => computed(() => format(toValue(date), formatOptions))

  return {
    format,
    formattedDate,
    timezone,
    timezoneMeta,
  }
}
