import numeral from 'numeral'
import type { ComputedRef, Ref } from 'vue'

export enum NumeralFormats {
  CLAIM_TOTAL = '0.[0]a',
  DASH_DOLLARS = '$0.[0]a',
  DETAIL_COUNTS = '0,0',
  DETAIL_DOLLARS = '$0,0.00',
  DETAIL_DOLLARS_WITHOUT_CENTS = '$0,0',
  LIST_COUNTS = '0[.]0[0]a',
  LIST_DOLLARS = '$0[.]0[0]a',
}

export { numeral }

export const formatCents = (
  valueInCents = 0,
  formatString = NumeralFormats.DETAIL_DOLLARS,
): string => {
  const n = numeral(valueInCents)

  if (!n.value()) return '$0'
  return n.divide(100).format(formatString)
}

export const formatCount = (
  value = 0,
  formatString = NumeralFormats.DETAIL_COUNTS,
): string => numeral(value).format(formatString)

export const formatRate = (value: number | string = 0): string => {
  const n = numeral(value)

  const intVal = n.value()

  if (!intVal) return '0%'

  const formatString =
    intVal < 0.1
      ? '0.00%'
      : intVal < 1.0
        ? '0.0%'
        : intVal < 10.0
          ? '0%'
          : intVal < 100.0
            ? '0,0%'
            : intVal < 1000.0
              ? '0.0a%'
              : '0a%'
  return numeral(value).format(formatString).toUpperCase()
}

export const useNumeralFormat = (
  num: Ref<number | string> | ComputedRef<number> | number | string,
  format: string,
): ComputedRef<string> => {
  const number = isRef(num) ? num : ref(num)

  return computed((): string => numeral(number.value).format(format))
}

export const useNumeralFormatWithTransition = (
  num: ComputedRef<number>,
  format: string,
) => {
  if (typeof num.value !== 'number') return useNumeralFormat(num, format)
  return useNumeralFormat(
    useThrottle(useTransition(num, { duration: 250 }), 20),
    format,
  )
}
