import type { AppConfigKeys, KvKeys } from '@rialtic/types'
import { featureFlags, parseAppConfig } from '@rialtic/types'
import { acceptHMRUpdate, defineStore } from 'pinia'

export const useAppConfiguration = defineStore('app-config', {
  state: () => {
    return featureFlags
  },

  actions: {
    async getKey(key: AppConfigKeys) {
      const { $datadog } = useNuxtApp()
      const api = useWorkersApi()

      try {
        const isSet: string | null = await api.fetchPublic(`/app-config/${key}`)

        this.$patch({
          [key]: !!isSet,
        })
      } catch (error) {
        $datadog.addError(error, {
          function: 'stores/appConfig:getKey',
        })
      }

      return this.$state
    },

    async getAll() {
      const { $datadog } = useNuxtApp()
      const api = useWorkersApi()

      try {
        const keys: KvKeys[] = await api.fetchPublic('/app-config')

        this.$patch(parseAppConfig(keys))
      } catch (error) {
        $datadog.addError(error, {
          function: 'stores/appConfig:getAll',
        })
      }

      return this.$state
    },

    async toggleKey(key: AppConfigKeys) {
      await this.updateAppConfigKey(key, !this.$state[key])
      return this.getAll()
    },

    async updateAppConfigKey(key: AppConfigKeys, isSet: boolean) {
      const { $datadog } = useNuxtApp()
      const api = useWorkersApi()

      const prevVal = this.$state[key]

      this.$patch({
        [key]: isSet,
      })

      try {
        if (isSet) {
          return api.fetch(`/admin/app-config/${key}`, {
            method: 'PUT',
            body: {
              value: 'true',
            },
          })
        }
        return api.fetch(`/admin/app-config/${key}`, {
          method: 'DELETE',
        })
      } catch (error) {
        this.$patch({
          [key]: prevVal,
        })

        $datadog.addError(error, {
          function: 'stores/appConfig:updateAppConfigKey',
        })

        throw error
      }
    },
  },
})

if (import.meta.hot)
  import.meta.hot.accept(acceptHMRUpdate(useAppConfiguration, import.meta.hot))
