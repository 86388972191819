import type { User } from '@auth0/auth0-vue'
import { createAuth0 } from '@auth0/auth0-vue'
import { defineNuxtPlugin } from '#app'

interface Auth0ReadyOptions {
  timeout?: number
  throw?: boolean
}

export default defineNuxtPlugin({
  name: '001-auth0',
  parallel: true,
  setup: (nuxtApp) => {
    const origin = new URL(location.href).origin
    const config = nuxtApp.$config.public.auth

    const plugin = createAuth0(
      {
        domain: <string>config.AUTH0_DOMAIN,
        clientId: <string>config.AUTH0_CLIENT_ID,
        cacheLocation: 'localstorage',
        authorizationParams: {
          redirect_uri: `${origin}${config.AUTH0_REDIRECT_PATH}`,
          ...(config.AUTH0_AUDIENCE ? { audience: config.AUTH0_AUDIENCE } : {}),
        },
      },
      {
        errorPath: '/auth/status',
        skipRedirectCallback: window.location.pathname === '/auth/callback',
      },
    )
    nuxtApp.vueApp.use(plugin)

    const auth0 = () => plugin

    return {
      provide: {
        auth0,
        auth0Ready: async (
          options: Auth0ReadyOptions = { timeout: 2000, throw: false },
        ): Promise<User | undefined> => {
          const { isLoading, user } = auth0()
          if (user.value?.sub) {
            return user.value
          }

          await Promise.any<boolean>([
            new Promise((resolve) => {
              const interval = setInterval(() => {
                if (!isLoading.value) {
                  clearInterval(interval)
                  resolve(true)
                }
              }, 100)
            }),
            new Promise((resolve) => setTimeout(resolve, options.timeout)),
          ])

          return user.value
        },
      },
    }
  },
})
