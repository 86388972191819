import {
  defineNuxtRouteMiddleware,
  navigateTo,
  useAppConfig,
  useNuxtApp,
} from '#imports'

const LOCALSTORAGE_AUTH_FULL_URL = 'auth-full-url'

export default defineNuxtRouteMiddleware(async (to) => {
  if (to.name === 'auth-login' || to.name === 'auth-callback') {
    return
  }

  if (to.query.error) {
    return navigateTo('/auth/status')
  }

  if (to.meta.public) {
    return
  }

  const { $auth0, $auth0Ready } = useNuxtApp()
  const { isAuthenticated, loginWithRedirect } = $auth0()

  await $auth0Ready()

  if (isAuthenticated.value) {
    if (process.client && localStorage) {
      const intendedUrl = localStorage.getItem(LOCALSTORAGE_AUTH_FULL_URL)
      if (intendedUrl) {
        localStorage.removeItem(LOCALSTORAGE_AUTH_FULL_URL)
        return navigateTo(intendedUrl)
      }
    }
    return
  }

  const appConfig = useAppConfig()
  if (appConfig.tenant?.supportCognitoAuth) {
    return
  }

  switch (to.path) {
    case '/signin':
    case '/signup':
    case '/signup/confirm':
    case '/reset-password':
    case '/reset-password-confirm':
      return navigateTo('/', { replace: true })

    default:
      break
  }

  if (process.client && localStorage) {
    localStorage.setItem(LOCALSTORAGE_AUTH_FULL_URL, to.fullPath)
  }

  const fallback = async () => {
    await new Promise<boolean>((resolve) => {
      setTimeout(() => {
        resolve(true)
      }, 2000)
      return navigateTo('/auth/login')
    })
  }

  await Promise.any([loginWithRedirect(), fallback()])
})
