import { TZDate, tz } from '@date-fns/tz'
import {
  endOfDay,
  format,
  formatISO,
  isAfter,
  isValid,
  parseISO,
  startOfDay,
} from 'date-fns'
import { defu } from 'defu'

export enum TimeZoneName {
  /** Alaska Time Zone */
  US_ALASKA = 'America/Anchorage',
  /** Central Time Zone */
  US_CENTRAL = 'America/Chicago',
  /** Eastern Time Zone */
  US_EASTERN = 'America/New_York',
  /** Hawaii-Aleutian Time Zone */
  US_HAWAII = 'Pacific/Honolulu',
  /** Mountain Time Zone */
  US_MOUNTAIN = 'America/Denver',
  /** Pacific Time Zone */
  US_PACIFIC = 'America/Los_Angeles',
  /** Puerto Rico Time Zone */
  US_PUERTO_RICO = 'America/Puerto_Rico',
  /** UTC */
  UTC = 'UTC',
}

export const intlDateFormats: Record<string, Intl.DateTimeFormatOptions> = {
  dayOfWeek: {
    weekday: 'long',
  },
  monDayYear: {
    month: 'short',
    day: '2-digit',
    year: 'numeric',
  },
  timeWithTimeZoneName: {
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
    timeZoneName: 'short',
  },
  timezone: {
    timeZoneName: 'short',
  },
}

export const timezoneMetadata: Record<
  TimeZoneName,
  { name: string; abbr: string; extendedAbbr: string[]; paapiValue: string }
> = {
  [TimeZoneName.US_ALASKA]: {
    name: 'US - Alaska Time',
    abbr: 'AKT',
    extendedAbbr: ['AKST', 'AKDT'],
    paapiValue: 'US_ALASKA',
  },
  [TimeZoneName.US_CENTRAL]: {
    name: 'US - Central Time',
    abbr: 'CT',
    extendedAbbr: ['CST', 'CDT'],
    paapiValue: 'US_CENTRAL',
  },
  [TimeZoneName.US_EASTERN]: {
    name: 'US - Eastern Time',
    abbr: 'ET',
    extendedAbbr: ['EST', 'EDT'],
    paapiValue: 'US_EASTERN',
  },
  [TimeZoneName.US_HAWAII]: {
    name: 'US - Hawaii Time',
    abbr: 'HT',
    extendedAbbr: ['HST', 'HDT'],
    paapiValue: 'US_HAWAII',
  },
  [TimeZoneName.US_MOUNTAIN]: {
    name: 'US - Mountain Time',
    abbr: 'MT',
    extendedAbbr: ['MST', 'MDT'],
    paapiValue: 'US_MOUNTAIN',
  },
  [TimeZoneName.US_PACIFIC]: {
    name: 'US - Pacific Time',
    abbr: 'PT',
    extendedAbbr: ['PST', 'PDT'],
    paapiValue: 'US_PACIFIC',
  },
  [TimeZoneName.US_PUERTO_RICO]: {
    name: 'US - Puerto Rico Time',
    abbr: 'AT',
    extendedAbbr: ['AST', 'ADT'],
    paapiValue: 'US_PUERTO_RICO',
  },
  [TimeZoneName.UTC]: {
    name: 'Coordinated Universal Time',
    abbr: 'UTC',
    extendedAbbr: ['GMT'],
    paapiValue: 'UTC',
  },
}

const DATE_ONLY_REG_EXP = /^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/

export const getSystemTimeZone = () => {
  return Intl.DateTimeFormat().resolvedOptions().timeZone
}

export const timezoneFormatter = (
  timeZone: TimeZoneName | string = getSystemTimeZone(),
  options: Intl.DateTimeFormatOptions = {},
  locales: Intl.LocalesArgument = 'en-US',
) => new Intl.DateTimeFormat(locales, defu({ timeZone }, options))

export const localDate = (inputDate: string): Date => {
  // When the time zone offset is absent, date-only forms are interpreted as a UTC time and date-time forms are interpreted as a local time.
  return DATE_ONLY_REG_EXP.test(inputDate)
    ? new Date(`${inputDate}T00:00:00`)
    : new Date(inputDate)
}

export const normalizeDate = (date: string | Date): Date => {
  if (date instanceof Date) {
    return date
  }

  return localDate(date)
}

export const formatDisplayDate = (
  date?: string | Date | null,
  formatPattern = 'MMM dd, yyyy',
  timezone: TimeZoneName | string = getSystemTimeZone(),
) => {
  if (!date || !isValid(new Date(date))) {
    return ''
  }

  return format(normalizeDate(date), formatPattern, {
    in: tz(timezone),
  })
}

/** Returns a Date and time formatted to match: Dec 13, 2023 at 12:01 AM EST */
export const formatDisplayDateTime = (
  date?: string,
  timeOnly = false,
  timezone?: TimeZoneName | string,
) => {
  if (!date) {
    return ''
  }
  const time = timezoneFormatter(
    timezone,
    intlDateFormats.timeWithTimeZoneName,
  ).format(new Date(date))

  if (timeOnly) return time

  return `${formatDisplayDate(date, 'MM/dd/yyyy', timezone)} at ${time}`
}

export const formatUtcISO = (
  date: Date | TZDate,
  representation: 'complete' | 'date' = 'complete',
) => formatISO(date, { representation, in: tz('UTC') })

export const stripTimeFromDate = (dateString: string | Date) => {
  if (!dateString) return ''

  if (dateString instanceof Date) {
    return format(dateString, 'yyyy-MM-dd')
  }

  return dateString.split('T')[0]
}

export const getStartOfDay = (
  date: string | Date,
  timezone: TimeZoneName | string = getSystemTimeZone(),
) => {
  return startOfDay(normalizeDate(date), { in: tz(timezone) })
}

export const getEndOfDay = (
  date: string | Date,
  timezone: TimeZoneName | string = getSystemTimeZone(),
) => {
  return endOfDay(normalizeDate(date), { in: tz(timezone) })
}

export const getDateRangeDates = (
  [start, end]: [string, string],
  timezone: TimeZoneName | string = getSystemTimeZone(),
): [Date, Date] => {
  return [getStartOfDay(start, timezone), getEndOfDay(end, timezone)]
}

export const getDateRangeTimestamps = (
  range: [string, string],
  timezone: TimeZoneName | string = getSystemTimeZone(),
  representation: 'complete' | 'date' = 'complete',
) => {
  return getDateRangeDates(range, timezone).map((date) =>
    formatUtcISO(date, representation),
  ) as [string, string]
}

export const isFutureDate = (
  date: Date,
  timezone: TimeZoneName | string = getSystemTimeZone(),
) => isAfter(date, TZDate.tz(timezone))

export const newTzDate = (
  date?: string,
  timezone: TimeZoneName | string = getSystemTimeZone(),
) => (date ? parseISO(date, { in: tz(timezone) }) : TZDate.tz(timezone))
