<script setup lang="ts">
import type { NuxtError } from '#app'

defineProps<{
  error: NuxtError
}>()

const handleError = () => clearError({ redirect: '/' })
</script>

<template>
  <div>
    <LogoRialtic class="ml-12 mt-8 block h-10" />
    <div class="text-primary-900 absolute left-2/4 top-52 -translate-x-2/4 p-5">
      <div class="font-display flex flex-col items-center">
        <h1 class="four-oh-four -tracking-4">{{ error.statusCode }}</h1>
        <div class="mb-12 text-3xl font-light">{{ error.statusMessage }}</div>
        <UiButton size="sm" type="button" @click="handleError">
          Back to Rialtic app
        </UiButton>
      </div>
    </div>
  </div>
</template>

<style scoped>
.four-oh-four {
  font-size: 200px;
  line-height: 100%;
}
</style>
