import Toast, { TYPE, useToast } from 'vue-toastification'
import { defineNuxtPlugin } from '#app'
import Button from './ToastClose.vue'
import './toast.css'

export default defineNuxtPlugin({
  name: 'vue-toastification',
  parallel: true,
  setup(nuxtApp) {
    nuxtApp.vueApp.use(Toast, {
      transition: 'Vue-Toastification__fade',
      closeButton: Button,
      toastDefaults: {
        // ToastOptions object for each type of toast
        /* @unocss-include (needed for unocss to recognize the icon classes)*/
        [TYPE.ERROR]: {
          icon: 'i-ph-bell-bold block h-5 w-5',
        },
        [TYPE.INFO]: {
          icon: 'i-ph-info-bold block h-5 w-5',
        },
        [TYPE.SUCCESS]: {
          icon: 'i-ph-check-circle-bold block h-5 w-5',
        },
        [TYPE.WARNING]: {
          icon: 'i-ph-bell-bold block h-5 w-5',
        },
      },
    })

    return {
      provide: {
        toast: useToast(),
      },
    }
  },
  env: {
    islands: false,
  },
})
