import * as v from 'valibot'

export const DateStringSchema = v.pipe(
  v.string('Must be string'),
  v.isoDate('Must be a date formatted as yyyy-MM-dd'),
)

const BooleanEnumSchema = v.picklist(['true', 'false'])
export const QueryBooleanSchema = v.pipe(
  v.optional(BooleanEnumSchema),
  v.transform((value) => value === 'true'),
)

export const QueryNumberSchema = v.pipe(
  v.string(),
  v.regex(/^\d+$/),
  v.transform((value) => +value),
)

export const QueryStringArraySchema = v.pipe(
  v.union([v.array(v.string()), v.string()]),
  v.transform((value) => (Array.isArray(value) ? value : [value])),
)
